body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EAF0FF;
  overflow-x: hidden;
  height: 100%;
  line-height: normal;
}
:root{
  --color-primary : #21ea85;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ms-Checkbox {
  margin-bottom: 10px !important;
}
.course_modal .ms-TextField-wrapper label{
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .form-signin-heading {
    text-align: center;
    padding-left: 20px !important;
  }

  /* .ms-TextField-wrapper {
    padding-left: 10px !important;
  } */
  .form-signin{
    /* max-height: 300px !important; */
    padding: 10px !important;
  }

  .checkboxContainer {
    padding: 8px !important;
  }

}

  .root {
    align-items: center;
    display: flex;
    height: 40px;
    padding-inline-end: 8px;
    /* min-width: 165px; */
    justify-content: space-between;

  }

  .expandIconWrapper {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
  }

  .expandIconWrapper.isOpen {
    transform: rotate(90deg);
  }

  .labelGridItem {
    padding-inline-start: 8px;
    display: flex;
    align-items: center;
    max-width: 265px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .labelGridItem div {
    max-width: 225px;
    overflow: hidden;
    max-height: 20px;
    text-overflow: ellipsis;
  }

  .editMode {
    padding-inline-start: 8px;
    display: flex;
    align-items: center;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .editMode div p {
    /* max-width: 144px; */
    overflow: hidden;
    max-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .viewMode div {
    max-width: 230px;
    overflow: hidden;
    max-height: 50px;
    text-overflow: ellipsis;
  }


  .actionButton {
    padding: 0;
    cursor: pointer;
    /* color: red; */
  }

  .actionButton button {
    padding: 0px;
  }

  .file_name_icon {
    width: 100%;
  }

  .file_name_icon,
  .file_action_icon {
    display: flex;
    align-items: center;
  }
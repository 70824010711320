.activeBadge {
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0px 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border: 2px solid #e6e6e6;
  border-bottom: none;
  color: cadetblue;
}

.ms-Dialog-main {
  width: 100%;
}

i {
  cursor: pointer;
}

.ms-Dialog-main {
  background-color: #eaeaea;
  border-radius: 10px;
}

.ms-TextField-fieldGroup {
  border: none;
  margin-bottom: 0px;
  background-color: #f4f4f4 !important;
}

.ms-Dialog-actionsRight {
  margin-top: 30px;
}

.ms-Button--primary {
  background-color: #80d100;
  border: 1px solid #80d100;
}

.importFromTemplate {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 50px;
  border: 1px dashed silver;
  margin-top: 20px;
  cursor: pointer;
}

.importFromTemplate input {
  display: none;
}

.upload_btn {
  margin-top: 10px;
  text-align: end;
}

.upload_btn button {
  border: none;
  padding: 7px 15px;
  border-radius: 6px;
  background-color: #80d100;
  color: white;

}

.templateLink {
  color: rgb(49, 17, 130);
  font-size: 15px;
  margin: 10px 0 10px 0;
  font-weight: 400;
  cursor: pointer;
}

.goBack {
  color: #4028b7;
  font-size: 20px;
  font-weight: 300;
  /* line-height: 50px; */
  cursor: pointer;
}

.backIcon {
  margin-right: 10px;
}

a,
.ms-Link {
  text-decoration: none !important;
  color: black !important;
}

p,
h3 {
  margin: 0;
}
.progressBarBody{
  position: fixed;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 100vw;
  z-index: 1000000;
}
.progressAnimation {
  z-index: 122222222;
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 5px 10px;
  border-radius: 9px;
}

.progressAnimation span {
  height: 19px !important;
  width: 19px !important;
}
.commonModal .MuiBox-root{
  width: 80%;
  max-width: 500px;
}
@media(max-width : 400px){
  .commonModal .MuiBox-root{
    width: 260px;
  }
}
.show_user_pass{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.show_user_pass p{
    font-size: 22px;
    font-weight: 500;
}
.addStudentField{
    margin-top: 10px    ;
}
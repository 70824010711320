.login_click{
    cursor: pointer;
    text-align: end;
    margin-top: 10px;
}
.login_click:hover{
    color: blue;
}
.login_btn_section{
    display: flex;
    justify-content: end;
}
.counter input {
  width: 50px;
  border: none;
  border-radius: 6px;
  ;
  background-color: #fff;
  text-align: center;
  padding: 6px;
}

.counter {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.addCounter {
  justify-content: center;
  border-radius: 10px !important;
}

.attempts_label {
  font-weight: 500;
}

.decreaseBtn {
  background-color: rgb(241 81 81 / 44%);
}

.increaseBtn {
  background-color: rgb(168, 226, 168);
}

.decreaseBtn,
.increaseBtn {
  border: none;
  border-radius: 6px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.view_btn {
  background-color: #80D100 !important;
  color: white !important;
}

.enable_btn {
  background-color: white !important;
  border: 1px solid rgb(235, 230, 230) !important;
}

.studentScoreDetail {
  display: flex;
  /* align-items: center; */
  gap: 20px;
  flex-wrap: wrap;
}

@media(max-width:800px) {
  .studentScoreDetail .counter {
    max-width: 100% !important;
  }

}

.counter span {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.analytic_table div .ms-DetailsList-headerWrapper .ms-FocusZone {
  display: flex !important;
  width: 100%;
  min-width: 1078px;
}

.analytic_table div .ms-DetailsList-headerWrapper .ms-FocusZone .ms-DetailsHeader-cell {
  width: 14% !important;
}

.analytic_table div .ms-DetailsList-headerWrapper .ms-FocusZone .ms-DetailsHeader-cell .ms-DetailsHeader-cellTitle {
  justify-content: center !important;
}

.analytic_table div .ms-DetailsList-contentWrapper .ms-FocusZone .ms-DetailsRow-fields .ms-DetailsRow-cell {
  width: 14% !important;
  text-align: center;
}

.analytic_table div .ms-DetailsList-contentWrapper .ms-FocusZone {
  width: 100%;
  display: flex;
}

.analytic_table div .ms-DetailsList-contentWrapper .ms-FocusZone .ms-List {
  width: 100%;
}

.analytic_table div .ms-DetailsList-contentWrapper .ms-FocusZone .ms-DetailsRow-fields {
  width: 100%;
  min-width: 1078px;
}

.analytic_table div .ms-DetailsList-contentWrapper .ms-FocusZone .ms-DetailsRow-fields:hover {
  background: rgb(243, 242, 241) !important;
}

.analyticContainer {
  padding-top: 10px;
}

.table_label {
  font-weight: 600;
  padding: 10px 0px;
  margin: 10px 0px;
}

.publish_btn button {
  background-color: #80D100;
  padding: 8px 15px;
  border: none;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}
.alertText{
  font-size: 16px;
  margin: 5px 0px;
  font-weight: 600;
}
.modalFooterbtn{
  display: flex;
  gap: 10px;
  justify-content: end;
  margin-top: 30px;
}
.modalFooterbtn button{
  border: none;
  border-radius: 5px;
  padding: 6px 10px;
  cursor: pointer;
}
.modalFooterbtn button:nth-child(1){
  background-color: #80D100;
  color: white;
}
.analyticClass{
  font-size: 10px;
}
.table_label{
  display: flex;
  gap: 30px;
}
.table_label ul{
  margin: 0;
  padding: 0;
}
.analyticAction{
  display: flex;
  gap: 10px;
  align-items: center;
}
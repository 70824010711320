.chart_body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.chart_body>div {
    width: 100%;
}
@media(max-width:1200px){
    .chart_body{
        grid-template-columns: repeat(2, 48%);
    }
}

@media(max-width:740px){
    .chart_body{
        grid-template-columns: repeat(1, 1fr);
    }
}

.submission_chart {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
}

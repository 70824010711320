.newlyAdded {
    background-color: #dddddd;
    padding: 1px 10px;
    border-radius: 5px;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.activeSidebar {
    background-color: white;
    transition: 0.5s;
    border-radius: 8px;
}

.deactiveSidebar {
    background-color: white;
    transition: 0.5s;
}

.deleteBtn {
    color: red !important;
}

.submission_Icon {
    font-size: 14px;
}

.tree-node:hover {
    box-shadow: 0 2px 8px 0 rgba(99, 99, 99, .2);
}

.customNodeFileName {
    cursor: pointer;
}

.sidebarName {
    cursor: pointer;
}

.sidebarTextList {
    max-width: 100%;
    display: flex;
}

.chapterFileName {
    max-width: 150px;
}

.folderInput {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    /* align-items: end; */
}

.folderInput p {
    font-size: 12px;
}

.folderInput svg {
    font-size: 18px;
}

.sbptext {
    max-width: 198px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 6px;
    max-height: 20px !important;
}

.sbptext img {
    height: 18px;
}

.inputFooter {
    display: flex;
    justify-content: space-between;
}

.iconOfDelete {
    display: flex;
    gap: 5px;
}

.iconOfDelete svg {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 2px;
}

.customNodeFileame {
    padding: 0px 5px;
}

.warning_sidebar {
    background-color: #fff0d4;
}

.medium_sidebar aside div div ul li .tree-node .customNodeFileame .sidebarName {
    max-width: 33px !important;
}

.qb_checkbox svg {
    height: 17px;
    width: 17px;
}
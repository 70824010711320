.mui_drop_select .MuiSelect-select {
    padding: 4px 6px;
}

.mui_drop_select .MuiFormLabel-root {
    top: -8px;
    font-size: 12px;
}

.mui_drop_select {
    flex: 1;
}


.mui_drop_select p {
    font-size: 14px;
    font-weight: 500;
}

.mui_drop_select .MuiOutlinedInput-notchedOutline legend {
    width: 0px !important;
}
.mui_drop_select .MuiFormControl-root {
    width: 100% !important;
    margin: 5px 0px !important;
}
/* .score_section .mui_drop_select .MuiFormControl-root{
    max-width: 190px;
} */
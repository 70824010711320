.available_course_absolute {
    position: absolute;
    top: 10px;
    right: 10px;
}
.Nothing_click{
    pointer-events: none;
}
.viewSubmissionLink{
    cursor: pointer;
}
.errorPageBody {
    background-color: #EAF0FF;
    text-align: center;
    height: calc(100vh);
    padding: 100px;
}

.errorPageMessage {
    margin: 0;
    font-size: 30px;
}

.loginPageLink {
    font-size: 15px;
}
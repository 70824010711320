.question {
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px 10px 0px;
}

.mcqSubOption {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 2px;
}

.mcqCorrectSubOption {
    background-color: #c7f1dc;
    padding: 10px;
    border-radius: 5px;
    margin: 2px;
}

.mcqWrongSubOption {
    background-color: #ff7783;
    padding: 10px;
    border-radius: 5px;
    margin: 2px;
}

.questionText {
    font-size: 18px;
}

.questionText p math semantics mrow mtable {
    display: flex;
}

.questionText p math semantics mrow {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.questionText p math semantics mrow mtable mtr {
    display: flex;
}

.questionText p math {
    display: flex;
    align-items: center;
}

.question_media {
    margin-top: 10px;
}

/* .score_detail {
    max-width: 200px;
} */

.question_seprator {
    position: relative;
}

.question_seprator::before {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: #e1dddd;
    bottom: -10px;
}

.sb_container {
    display: flex;
    gap: 10px;
}

@media(max-width : 800px) {
    .sb_container {
        padding-top: 8px;
    }
}

.wrong_correct_score {
    flex-direction: column;
    align-items: start;
}

.wrong_correct_score div {
    display: flex;
    gap: 10px;
}

.submission_studentScoreDetail {
    align-items: normal;
}

.sub_details {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    margin-bottom: 2px;
}

.sub_details li {
    color: #a5a4a4;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    gap: 6px;
    position: relative;
    align-items: center;
}

.sub_details li::before {
    content: "";
    height: 7px;
    width: 7px;
    background-color: #a5a4a4;
    position: absolute;
    left: -12px;
    top: 5px;
    border-radius: 100%;
    margin-top: 4px;
}

.subject_keys {
    font-weight: 200;
    font-size: 16px;
}

.submission_filter {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.submission_filter .MuiAutocomplete-root {
    flex: 1;
}

.correctTextAnswer {
    color: green;
}

.wrongTextAnswer {
    color: red;
}
.showCorrectList{
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0 20px;
    list-style: disc;
}
.multipleCorrect{
    display: flex;
    gap: 5px;
    margin-top: 10px;
}
.add_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.score_section {
  display: flex;
  gap: 5px 20px;
  flex-wrap: wrap;
}
.score_section .ms-TextField{
  width: 100%;
}
.add_edit_body {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.course_type_radio .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
}

.course_type_radio label {
  color: #323130;
  font-size: 14px;
  font-weight: 500;
}

.MuiFormGroup-root .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px;
}

.update_btn {
  background-color: #80d100;
  padding: 6px 10px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}

.cancel_btn {
  border: 1px solid #241e1e;
  padding: 6px 10px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}
.config_footer {
  display: flex;
  justify-content: end;
}
.config_container {
  display: flex;
  gap: 20px;
  flex-direction: row !important;
  justify-content: space-between;
}

.publish-popup .MuiBox-root {
  min-width: 480px !important;
}
.swal2-container {
  z-index: 11111;
}

.config_body {
  display: flex;
  gap: 20px;
}

.config_body ul {
  height: calc(100vh - 185px);
  overflow: auto;
}

.config_body .MuiBox-root {
  padding: 0;
}
.config_ul {
  background-color: white !important;
}

@media(min-width:1024px){

  .config_modal > .MuiBox-root {
    min-width: 800px;
  }
}
@media(max-width:1024px){

  .config_body {
    flex-wrap: wrap-reverse;
  }
}


.config_icon {
  padding: 10px 15px;
}

.config_edit_section .MuiAutocomplete-root {
  margin: 10px 0px;
  min-width: 250px !important;
}


.level_head p {
  text-align: center;
}

.class_time {
  display: flex;
  flex-direction: column;
}

.slider_level {
  display: flex;
  margin-top: 25px;
}

.slider_level label {
  min-width: 80px;
}

.slider_level > span {
  width: 75%;
}

.selectAllCheckbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

ul.question_avail {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 0;
  height: auto;
}

ul.question_avail li {
  font-size: 12px;
}

.availble_btn {
  background-color: #80d100;
  color: white;
}

.inline_label {
  margin-top: 8px;
}

.inline_label {
  display: flex;
  gap: 10px;
  align-items: end;
}

.inline_label > div {
  flex: 1;
}

.inline_label .ant-picker {
  height: 32px;
  background-color: #f4f4f4 !important;
}
.sortbyBtn{
  height: 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #fff;
}
.sorting{
  list-style: none;
  margin: 0;
  padding: 0;
}
.sorting li{
  padding: 6px 15px;
  font-weight: 500;
  cursor: pointer;
}
.sorting li:hover{
  background-color: #f5f5f5;
}
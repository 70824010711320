.finalsubmit_btn{
    display: flex;
    justify-content: end;
}
.test_actions{
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 0px 20px;
}
.test_timer_submit{
    display: flex;
    gap: 10px;
    align-items: center;
}
.cs_input_range{
    border: none;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    background-color: #f5f5f6;
    

}
.mcqCheckbox_student .questionFieldBody{
    padding-left: 0;
    min-width: auto !important;
}
.mcqCheckbox_student .questionFieldBody .editorDiv{
    background-color: #fff;
    padding: 10px 0px;
}
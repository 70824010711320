.classesBody {
  padding: 0px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.addCourseBody .loginField {
  margin-top: 2px;
}

.test_section {
  display: flex;
  gap: 10px;
}

.addCourseBody .loginField>div>div {
  height: 35px;
  border-radius: 0px;
}

.add_course_modal .MuiBox-root {
  background-color: #eaeaea;
}
.dashboard_home{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.publish-popup .MuiBox-root {
  min-width: 480px !important;
}
.header_with_search{
  padding-top: 6px;
  padding-bottom: 4px;
  flex-wrap: nowrap;
}
.search_field{
  background: #ffffff;
}
.addBtn {
    background-color: #80d100;
    color: white;
    padding: 8px 16px;
    border-radius: 0px !important;
    /* border: 1px solid #80d100; */
}

.modal_ok_btn {
    background-color: var(--color-primary-green);
    border: 1px solid var(--color-primary-green);
    color: white;
}

.modal_cancel_btn {
    background-color: #fff;
    border: 1px solid black;
}

.modal_footer_btn {
    display: flex;
    justify-content: end;
    gap: 15px;
    margin-top: 30px;
}

.modal_footer_btn button {
    border-radius: 0px;
    min-width: 80px;
}
.common_button{
    border: none;
    border-radius: 6px;
    padding: 5px 10px;
    /* margin: 0px 5px; */
    cursor: pointer;
}

.disable_btn{
    cursor: no-drop;
}
.disable_view_btn{
    border: 1px solid #e9e3e3;
}
.test_confirmation ul {
  text-align: start;
  padding: 0;
}
.test_confirmation ul li {
  display: flex;
  align-items: center;
}
.test_confirmation ul li p {
  min-width: 145px;
  font-weight: 500;
}
.test_confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.st-title-cnt {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 0px;
}

.st-sort-cnt {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.st-sort-cnt .MuiInputBase-root {
  max-width: 250px;
  /* margin: 10px 20px; */
}

.add_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.score_section {
    display: flex;
    gap: 20px;
}

.add_edit_body {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.course_type_radio .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
}

.course_type_radio label {
    color: #323130;
    font-size: 14px;
    font-weight: 500;
}

.MuiFormGroup-root .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
}

.update_btn {
    background-color: #80d100;
    padding: 6px 10px;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
}

.cancel_btn {
    border: 1px solid #241e1e;
    padding: 6px 10px;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}
.config_footer{
    display: flex;
    justify-content: end;
}
.config_container{
    display: flex;
    gap: 20px;
    flex-direction: row !important;
}

.institute-img{
    width: 120px;
}

.institute-popup .MuiBox-root {
    min-width: 80vw !important;
}
.common_button{
    border: none;
    border-radius: 6px;
    padding: 5px 10px;
    /* margin: 0px 5px; */
    cursor: pointer;
}

.disable_btn{
    cursor: no-drop;
}
.disable_view_btn{
    border: 1px solid #e9e3e3;
}
.modal_heading {
    margin-bottom: 15px;
}

.teacher_modal .MuiBox-root {
    width: 280px;
}

.teacher_modal .MuiBox-root .loginField>div>div {
    height: 30px;
    border-radius: 3px;
}

.teacher_modal .MuiBox-root .loginField {
    margin-top: 5px;
}

.clipboardScreen {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.clipboardScreen button {
    border: none;
    background-color: aliceblue;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;

}

.copiedText button {
    background-color: #97fd97;
    color: #7d7d7d;
    white-space: nowrap;
}

.username_pass {
    list-style: none;
    padding: 0;
}

.username_pass span {
    font-weight: 500;
}

.studentCreatedDate {
    font-size: 13px;
    font-weight: 500;
    color: gray;
}

.studentCreatedDateLabel {
    font-weight: 500;
    font-size: 12px;
    color: gray;
}

.nameWithDate {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.copyLink {
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.swal2-confirm {
    background-color: #80d100 !important;
}

.section_field {
    display: flex;
    justify-content: space-between;
}

.section_field .loginField {
    flex: 1;
}

.section_field .ai_checkox {
    margin-top: 30px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;

}

.section_field .ai_checkox label {
    font-size: 12px;
}

.configIcon {
    cursor: pointer;
}

.inline_label {
    display: flex;
    gap: 10px;
    align-items: end;
    flex-wrap: wrap;
}

.inline_label>div {
    flex: 1;
}

.inline_label .ant-picker {
    height: 32px;
    background-color: #f4f4f4 !important;
}

.time_label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
}

.config_body {
    display: flex;
    gap: 20px;
}

.config_body ul {
    height: calc(100vh - 185px);
    overflow: auto;
}

.config_body .MuiBox-root {
    padding: 0;
}

.config_ul {
    background-color: white !important;
}

.import_container {
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--color-primary);
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
}
.import_icon{
    font-size: 12px;
}

@media(min-width:1024px) {
    .config_modal>.MuiBox-root {
        min-width: 800px;
    }

}




.config_icon {
    padding: 10px 15px;
}

.config_edit_section .MuiAutocomplete-root {
    margin: 10px 0px;
}

.level_head p {
    text-align: center;
}

.class_time {
    display: flex;
    flex-direction: column;
}

.slider_level {
    display: flex;
    margin-top: 25px;
}

.slider_level label {
    min-width: 80px;
}

.slider_level>span {
    width: 75%;
}

.selectAllCheckbox {
    display: flex;
    gap: 10px;
    align-items: center;
}

ul.question_avail {
    display: flex;
    gap: 10px;
    list-style: none;
    padding: 0;
    height: auto;
}

ul.question_avail li {
    font-size: 12px;
}

.availble_btn {
    background-color: #80d100;
    color: white;
}

.swal2-container {
    z-index: 111111;
}

@media(max-width:700px) {
    .manageTeacherBody>div {
        flex: 1;
    }
}

.badge {
    padding: 5px 10px;
}


.update_btn {
    background-color: #80d100;
    padding: 6px 10px;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
}

.cancel_btn {
    border: 1px solid #241e1e;
    padding: 6px 10px;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}

.config_footer {
    display: flex;
    justify-content: end;
}

.config_container {
    display: flex;
    gap: 20px;
    flex-direction: row !important;
}

.inline_label {
    margin-top: 8px;
}

.show_label p{
    margin-top: 4px;
}

.addCourseBody .loginField{
    margin-top: 0 !important;
}
.dtbadge span{
    cursor: pointer;
}
.dtbadge span:hover{
    transform: scale(1.1);
    transition: 0.5s;
}
.pdfQuestions ul {
    display: flex;
    gap: 30px;
    margin-left: 50px;
    flex-wrap: wrap;
    padding: 0px 32px;
}

.border_bottom_pdf {
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}

.pdfQuestions ul li {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    font-size: 14px;
}

.pdfQuestionheading {
    display: flex;
    gap: 5px;
    margin: 5px 0px;
    padding: 0px 30px;
    align-items: center;
    font-size: 14px;
}

.pdfBody {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.pdfcorrectAnswer {
    font-weight: 600;
}

.pdfHeadtext {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    padding-top: 15px;
}

.exportPdf {
    background-color: #80d100;
    color: white;
    margin-left: 6px;
}

.pdfCourseDescription {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.pdfContent li {
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    transition: 0.5s;
}

.pdfContent li p {
    font-size: 14px;
    font-weight: 600;
    /* margin: 10px 0px; */
}

.pdfContent li:hover {
    /* background-color: #f5f5f5; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pdfQuestionheading>span {
    font-size: 14px;
    min-width: 25px;
}

.pdfQuestionheading>.pdfcorrectAnswer {
    width: fit-content;
    padding-left: 45px;
}

.pdfDescription {
    padding-left: 60px;
}

/* .border_seprator{
    display: flex;
    justify-content: center;
    width: 100%;
} */
.question_heading {
    border-bottom: 2px solid #c2c0c0;
    /* width: 96%; */
}

.question_heading:nth-last-child() {
    border: none;
}

/* .question_heading{
    position: relative;
}
.question_heading::after{
    content: "";
    height: 1px;
    width: 100%;
    background: gray;
    left: 0;
    bottom: 0;
    position: absolute;
} */

.correct_heading {
    margin-top: 15px;
}

.pdfDescription {
    margin-top: 15px;
}

@page {
    border: 1px solid;
}

@media print {
    .pdf_qus_opt {
        page-break-inside: avoid;
    }
    @page {
        border: 1px solid gray;
    }
}
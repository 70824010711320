.profile_field {
    display: flex;
    gap: 30px;
    border-bottom: 2px solid #8080805e;
    /* align-items: center;
    padding: 6px 10px; */
}

.profile_label {
    min-width: 150px;
    font-size: 14px;
    color: black;
    text-align: end;
    font-weight: 500;
    position: relative;
    padding: 15px 10px;
    background-color: rgba(43, 42, 42, 0.12);
}
.acount_status{
    color: darkgray;

}

.profile_name {
    font-size: 20px;
    font-weight: 500;
}

.profile_section {
    display: flex;
    flex-direction: column;
    gap: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.profile_header {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
    flex-direction: column;
}


.profile_body {
    margin-top: 40px;
}

/* .profile_label:after {
    content: ":";
    position: absolute;
    right: 0;

} */

.profile_field .ms-TextField {
    max-width: 220px;
    min-width: 160px !important;
}

.save_btn_section {
    display: flex;
    justify-content: center;
}

.saveBtn {
    margin: 30px 0px;
    min-width: 150px;
    padding: 8px;
    font-size: 20px;
    font-weight: 700;
    background: #306eb3;
    color: white;
}

.activeState {
    color: green;
    font-weight: 600;
}

.inActiveState {
    color: red;
    font-weight: 600;
}

.profile_value {
    display: flex;
    gap: 10px;
    min-height: 35px;
    align-items: center;
}

.profile_table_label{
    background-color: gray;
    min-width: 120px;
}
.profile_table tr {
    border: 2px solid #8080805e ;
}

.profile_field:nth-child(even){
    border-right: 2px solid #8080805e;
    border-left: 2px solid #8080805e;
}
.profile_field:nth-child(odd){
    border-left: 2px solid #8080805e;
}
.profile_field:last-child {
    border-right: 2px solid #8080805e;
}
.profile_field:nth-child(1) , .profile_field:nth-child(2){
    border-top: 2px solid #8080805e;
}


@media(max-width:768px) {
    .profile_label {
        max-width: 200px;
    }
    .profile_section {
       
        grid-template-columns: repeat(1, 1fr);
    }
    .profile_label:after {
        display: none;
    }
    
    .profile_field {
        border-right: 2px solid #8080805e;
    }
    .profile_field:first-child {
        border-top: 2px solid #8080805e;
    }
    .profile_field:nth-child(2){
        border-top: 0px solid #8080805e;
    }
}
.profile_camera{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.sidenavDashContainer {
    display: flex;
    justify-content: space-between;
    /* margin-top: 60px; */
}

.dashboardElementContainer {
    display: flex;
    /* justify-content: space-between; */
    flex-grow: 10;
    overflow: auto;
    flex-direction: column;
    position: relative;
    /* padding: 10px 0px 0px 10px;
    max-height: calc(100vh - 70px); */

}
.createrStudioCont{
    flex-direction: column !important;
    /* padding: 10px; */
    padding-bottom: 0;
    margin-right: 20px;
    max-height: calc(100vh - 193px);
}

.dashboardBody {
    background-color: #EAF0FF;
}

/* width */
::-webkit-scrollbar{
    width: 7px;
    border-radius: 7px; 
}
.creatorStudioSidebars::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 7px;
}



/* Track */
::-webkit-scrollbar-track{
    background-color: #f1f1f1;
}


/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(190, 190, 190);
    border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(190, 190, 190);
    border-radius: 7px;
}

@media(max-width:800px){
    .dashboardElementContainer{
        overflow: initial;
        padding: 10px;
    }
}

.ant-table-wrapper::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    border-radius: 7px;
}
.ant-table-content::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    border-radius: 7px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 0px 15px
}

.leftHeaderContainer,
.rightHeaderContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.studentClassName {
  font-weight: 600;
}

.logoIcon {
  font-size: 20px;
  font-weight: bold !important;
  padding-right: 20px;
}

.logoName {
  font-size: 21px;
  font-weight: 600;
  padding-right: 20px;
}

.searchBar {
  width: 600px;
  border: none;
  border-radius: 20px;
  padding-right: 20px;
}

.rightIcons {
  display: flex;
}

.widgetIcons {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: auto;
  text-align: center;
  padding-right: 8px;
}


.clientName {
  margin: 0;
  margin-bottom: -5px;
  font-size: 15px;
}

.powerdBy {
  font-size: 9px;
}

@media only screen and (max-width: 600px) {
  /* .clientDetails {
    display: none;
  } */

  .headerContainer {
    padding: 0px 15px;
    column-gap: 10px;
    padding-top: 4px;
  }

  .clientLogoBody>img {
    height: 35px;
  }

  .searchBar {
    width: 200px;
  }

  .rightIcons {
    display: none;
  }

  /* .logOut {
    display: none;
  } */

  .persona {
    margin-top: 5px;
  }

}

@media(max-width : 800px) {
  .overlay {
    width: 100% !important;
    left: 0px !important;
    right: 0;
    bottom: 0;
    border-radius: 2px !important;
  }

  /* .collapse_menu {
    top: -37px !important;
  } */
}

.callout_head {
  top: 60px !important;
  right: 15px !important;
  left: auto !important;
  max-height: 631.686px;
  background: white;
  border-radius: 10px;
  min-width: 210px;
  padding: 15px 0px;
}

.callout_head .ms-Callout-main {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.menuIcon {
  font-weight: 900;
  font-size: 20px;
  cursor: pointer
}

.largeRightHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mobileRightHeader {
  display: none;
}

@media(max-width : 800px) {
  .largeRightHeader {
    display: none;
  }

  .mobileRightHeader {
    display: block;
  }
}

.userProfile {
  position: relative;
}

.view_profile {
  position: absolute;
  background: white;
  /* padding: 10px; */
  display: none;
  right: 20px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
  border-radius: 10px;
  z-index: 2 !important;
}

.view_profile>div {
  border-radius: 10px;
}

.userProfile:hover .view_profile {
  display: flex;
  z-index: 1;
  min-width: 200px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.view_profile button {
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.logOut {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.view_profile .logOut {
  padding: 4px 10px;
  border-radius: 6px;
}

.view_profile .logOut:hover {
  background-color: #f5f5f5;
}

.help_section_btn svg {
  font-size: 24px;
}

ul.header_list .MuiListItemIcon-root {
  min-width: 30px !important;
}

ul.header_list .MuiButtonBase-root {
  /* min-width: 20px !important; */
  padding: 5px 15px;
}

.sideNavIcon {
  font-size: 30px;
  margin: 10px 15px;
}

.activeSideNavIcon {
  font-size: 26px;
  padding: 10px;
  margin: 10px 15px;
  background-color: black;
  color: #FFFFFF;
  border-radius: 50%;
  box-sizing: content-box;

}

svg.activeSideNavIcon {
  font-size: 26px ;
}

@media(max-width:800px) {
  svg.activeSideNavIcon {
    font-size: 26px;
  }

}


.sideNavContainer {
  height: calc(100vh - 50px);
  flex-grow: 0;
  flex-flow: column;
  display: flex;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
}

@media (max-width: 800px) {
  .sidenavDashContainer {
    display: block !important;
  }

  .sideNavContainer {
    height: 44px;
    flex-grow: 0;
    flex-flow: row;
    display: flex;
  }

  .activeSideNavIcon {
    font-size: 16px;
    padding: 6px;
    margin: 5px 22px;
    background-color: black;
    color: #FFFFFF;
    border-radius: 50%;
  }

  .sideNavIcon {
    font-size: 16px;
    padding: 0px;
    margin: 13px 15px;
  }
}

@media(max-width:390px){
  .sideNavIcon , .activeSideNavIcon{
    margin: 5px 10px;
  }
}
.ant-table-cell{
    padding: 10px !important;
}
.analytics_enable .MuiBox-root{
    background: #e6e6e6;
}
.analytics_enable .counter{
    padding: 20px 0px;
}
.table_label{
    margin: 12px 0px;
}
.MuiAccordionSummary-root.Mui-expanded {
    background-color: #f5f5f5;
}

.fussy_input {
    border: none;
    outline: none;
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 10px 8px;
    background: #EAF0FF;
}
:root {
    --mobile-screen: 600px;
    --color-primary-green : #80d100;
}

@media(max-width:800px){
    .dtbadge {
        padding-left: 5px;
    }
    /* .studentScoreDetail{
        gap: 0px;
    } */
    .counter{
        /* border-radius: 10px; */
        padding: 5px 20px;
        max-width: 33%;
        min-width: 200px;
    }
    .addSubject{
        width: 50%;
        min-width: auto;
        max-height: 100px;
    }
    .homeWorksHeader{
        padding-left: 5px;
    }
    .subjectDetails {
        padding: 10px;
    }

}
.MuiBox-root{
    outline: none;
}

.help_section_img{
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    align-items: center;
}
.help_container p{
    margin:10px 0px;
    font-size: 14px;
    font-weight: 500;
}
.help_container ul{
    list-style: none;
}
.help_container ul li{
    font-size: 14px;
    font-weight: 500;
}
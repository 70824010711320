.ql-snow .ql-tooltip{
    z-index: 100;
    left: initial !important;
}
.ql-editor{
    min-height: 150px;
}
.mathquill4quill-operator-button{
    min-width: 50px;
    width: auto !important;
}
.blot-formatter__toolbar-button{
    display: none !important;
}
.modalTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.add_course_modal .MuiBox-root {
    padding: 20px;
}

.commonModal .MuiBox-root {
    max-height: 85vh;
    overflow: auto;
    /* min-width: 440px; */
}
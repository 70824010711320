.scorebaord_table .ms-DetailsHeader div , .scorebaord_table .ms-DetailsRow-fields div{
    flex-grow: 1;
}
.scoreTooltip {
    list-style: none;
    padding: 0;
    min-width: 100px;
    text-align: center;
}
.scoreFilter{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 100%;
    /* justify-content: center; */
}
.filter_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}
.emptyText{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
}
.refresh_btn{
    min-width: 100px;
    max-height: 50px;
}
.ms-DetailsHeader-cellSizer{
    display: none;
}
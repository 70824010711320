.ms-Persona {
    height: 30px;
}

.callout_head .ms-Callout-main>div {
    width: 100%;
    padding:5px 15px;
}
.callout_head .ms-Callout-main>div:hover{
    background-color: #f5f5f5;
}
.admin_name{
    display: flex;
    gap: 8px;
}
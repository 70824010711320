.dtbadge {
  display: flex;
  justify-content: center;
  /* margin-top: 10px; */
}

.departments {
  margin: 0px 0 20px 0;
}

.department {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}

.departmentBody {
  background-color: #fee4cb;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  /* margin-top: 10px; */
}


.dtDepartment {
  padding-top: 10px;
}

.ms-Button-menuIcon {
  display: none !important;
}

.classContainer {
  /* display: grid;
    grid: auto auto / auto auto auto auto auto auto auto; 
    grid-gap: 10px; */
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  padding-bottom: 10px;
}

.class {
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
}

@media(max-width:889px) {
  .class {
    /* width: 36%; */
    flex-grow: 1;
    max-width: 42%;
    min-width: 30%;
    flex-basis: auto;
  }

  .subject,
  .addSubject {
    flex: 1;
  }

  .counter {
    flex-grow: 1;
  }

  .counterPara {
    flex-grow: 0 !important;

  }
}

.addClass {
  text-align: center;
  padding-top: 40px;
  padding-right: 10px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradeName {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.gradeDisplayName {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  /* padding-bottom: 10px; */
}

.classSupervisor {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.manageTeacherBody {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.addTeacher,
.importTeacher {

  height: 100px;
  min-width: 170px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #e6e6e6;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;

}

@media(max-width:500px) {

  .addTeacher,
  .importTeacher {
    /* flex-grow: 1 !important;
  
  min-width: 100px; */
    /* width: 43% !important; */
    width: 27% !important;
    padding: 6px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }

  .addteacherLabel {
    font-size: 16px !important;
  }
}

.addteacherLabel {
  margin-top: 10px;
  font-size: 16px;
}

.teachersList {
  margin: 20px 0 20px 0;
}

.teacher {
  display: flex;
  background-color: #e6e6e6;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 8px;
  align-items: center;
}

.teacherActions {
  flex-grow: 1;
  text-align: right;
  padding: 10px 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.teacherActions>i {
  font-size: 15px;
  margin: 0 5px;
  color: #000000;
}

.classActions>i {
  font-size: 15px;
  margin-left: 8px;
  color: #000000;
}

.facePile img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 3px 0 0 5px;
}

.facePile {
  flex-grow: 1;
  max-width: 70px;
  min-width: 48px;
  text-align: center;
}

.teacherDetails {
  /* flex-grow: 8; */
  width: 83%;
  padding-top: 5px;
}

.teacherName {
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}

.classIncharge {
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px;
}

.notFound {
  background-color: #e6e6e6;
  font-size: 20px;
  /* padding: 150px; */
  border-radius: 10px;
  text-align: center;
  min-height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumb_header {
  display: flex;
  justify-content: end;
  margin-bottom: 5px;
  flex-wrap: wrap-reverse;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  overflow: hidden;

}

.enableEdit {
  background: black;
  color: white;
  padding: 6px 18px;
  /* margin-left: 20px; */
}

.activeEdit {
  background-color: #f6f6f6 !important;
  color: black !important;
}

.dateAndEdit {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 10px;
}

.department_and_teacher_header {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  flex-wrap: wrap;
}

.dateTime {
  white-space: nowrap;
}

.timeWithAction {
  display: flex;
  gap: 10px;
}

.refresh_with_time {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-bottom: 6px;
  justify-content: end;
}

@media(max-width:420px) {
  .refresh_with_time {
    gap: 5px !important;
  }
}

.page_header {
  display: flex;
  padding-right: 20px;
  font-weight: 600;
  justify-content: space-between;
  align-items: end;
}

.breadcrumb_text {
  background-color: white;
  padding: 10px 15px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-weight: 600;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.breadcrumb_text a {
  transition: 0.5s;
}

.breadcrumb_text a:hover {
  color: blue !important;
  text-decoration: underline !important;

}

.buttonWithicon {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
  padding: 7px 10px;
  color: white;
  background-color: black;
  border-radius: 6px;
  font-weight: 600;
  border: none;
  white-space: nowrap;
}

.buttonWithicon i {
  font-size: 15px;
  margin: 0;
}

.time_text {
  background-color: #ffffff !important;
  padding: 5px 15px;
  border-radius: 7px;
  font-weight: 600;
  white-space: nowrap;
}

.timeEdit {
  display: flex;
  gap: 10px;
}

.deleteBtn {
  background-color: red;
}

.allCourses {
  display: flex;
  flex-wrap: wrap;
}

.student_course {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.teacher_email {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.courseModal {
  max-height: 80vh;
  overflow: auto;
}

.download_btn {
  padding: 7px 15px;
  border: none;
  border-radius: 7px;
  background: #80d100;
  color: white;
}

.downloadIcon {
  margin-right: 6px;
}

.duplicateUser {
  background-color: #ffeded;
  color: red;
}

.duplicateUser .teacherActions>i {
  color: red;
}

.react-swipeable-view-container {
  padding: 10px 0px;
}

.activeEditAllCourse {
  background-color: #f6f6f6 !important;
  color: black !important;
}

.modal_heading {
  font-size: 20px;
  font-weight: 600;
}

.teacherName {
  display: flex;
  gap: 5px;
}

.teacherName p {
  white-space: nowrap;
}

.tooltipText {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media(max-width:360px) {
  .manageTeacherBody {
    gap: 0;
  }

  .nameWithDate {
    gap: 5px;
  }
}
.changePassword .logoBody img{
    width: 200px;
}
.submit{
    color: white;
    padding: 10px   ;
}
.changePassword .submitBtn{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.disable_btn{
    background-color: #e6e6e6 !important;
    cursor: no-drop;
    color: black;
}
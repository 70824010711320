.sideIcon{
    position: absolute;
    top: 0;
    right: -35px;
    padding: 15px 10px;
    background: #eaf0ff;
    border-radius: 0px 10px 10px 0px;
    cursor: pointer;
}

.medium , .full{
    transform: rotate(180deg);
    transition: 0.8s;
}
.small{
    transform: rotate(0deg);
    transition: 0.8s;
}
.medium_sidebar .sbptext{
    max-width: 55px;
    transition: 1s;
}
.medium_sidebar aside{
    width: 90px;
    min-width: 90px;
    transition: 1s;
}
.subject_dropdown{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0px;
    flex: 1;
}
.subject_dropdown .MuiAutocomplete-root {
    flex: 1;
    width: auto;
}
.subject_dropdown>div{
    min-width: 150px;
    flex: 1;
}
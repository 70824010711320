.addStudentContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.addStudentContainer .addDepartmentForm {
    width: 450px;
}
.addStudentContainer .loginField{
    margin-top: 15px;
}
.addStudentContainer .ms-TextField{
    margin-top: 15px;
}
.logo_img{
    height: 50px;
}
.addStudentContainer .loginField > div > div{
    height: 30px !important;
}

 @media(max-width:568px){
    .addStudentContainer .addDepartmentForm{
        width:100%
    }
 }
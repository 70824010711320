.homeworkElementsContainer {
  background-color: #ffffff;
  height: auto;
  padding: 10px 10px;
  margin-right: 20px;
  flex-grow: 10;
  padding-bottom: 20px;
  border-radius: 10px 10px 10px 10px;
  min-height: calc(100vh - 165px);
  position: relative;
}

.fullScreenBody {
  margin-right: 0;
}

.add_course_text {
  margin-top: 4px;
}

h2 {
  margin: 0;
}

.homeWorksHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  margin-bottom: 10px;
}

.counterMenuContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
}

.counterContainer {
  display: flex;
  gap: 10px;
  /* flex-wrap: wrap; */
  width: 100%;
}

.counter {
  background: #e6e6e6;
  padding: 19px 20px;
  border-radius: 10px;
  align-items: center;
  box-sizing: border-box;
  max-width: 33%
}

.homeworkIcons {
  font-size: 30px;
  /* margin-left: 10px; */
}

.subjectGrid {
  /* margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; */
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.subject,
.addSubject {
  height: auto;
  background-color: #fee4cb;
  padding: 10px;
  margin-bottom: 0.76%;
  border-radius: 10px;
  width: 20.5%;
  float: left;
  margin-right: 0.76%;
  min-width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.addSubject {
  justify-content: center;
  margin: 0;
}

.boxHeading {
  transition: 0.5s;
  cursor: pointer;
}

.subject:hover .boxHeading {
  font-weight: 800;
  color: blue;
  text-decoration: underline;
}

.Nothing {
  cursor: initial;
  color: gray;
}


.subject:hover .Nothing {
  font-weight: 800;
  color: gray;
  text-decoration: none;
}


.addSubjectIcon {
  font-size: 30px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 50%;
}

.addNewSubject {
  text-align: center;
  /* padding-top: 125px; */
}

.dateAndMore {
  display: flex;
  justify-content: end;
}
.subjectFacePile{
  display: flex;
  align-items: center;
  gap: 15px;
}

.subjectFacePile>img {
  padding: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.subjectFacePile i{
  font-size: 20px;
}

.subjectDetails {
  /* text-align: center; */
  padding: 30px 10px;
}

.teacherName {
  /* margin-top: 5px; */
  margin: 0 !important;
  font-weight: 400 !important;
  padding-top: 5px;
  font-size: 15px !important;
}

.progressBar {
  width: 100%;
  background-color: #ffffff;
  height: 5px;
  border-radius: 5px;
  margin-top: 5px;
}

.progress {
  background-color: #ff942e;
  width: 60%;
  height: 5px;
  border-radius: 5px;
}

.progressPercent {
  float: right;
  font-weight: 500;
}

.marks {
  margin-top: 10px;
  display: flex;
  margin-top: 50px;
}

.marksBody {
  background-color: #ffffff;
  width: 30px;
  padding: 2px 4px;
  border-radius: 15px;
  font-weight: 500;
  margin-right: 5px;
}

.obtainedMarks {
  border-bottom: 3px solid black;
  padding: 0 5px;
}

.totalMarks {
  padding: 0 5px;
}

.addMarks {
  font-size: 20px;
  padding: 15px 10px;
}

.viewSubmissionLink {
  margin-top: 15px;
  color: blue !important;
}

.testDetail p {
  font-size: 16px;
  margin: 5px 0px;
}

.disableSubmission {
  color: gray !important;
  pointer-events: none;
  cursor: no-drop;
}

.viewAnalytic i {
  font-size: 22px;
}

.footerBtn {
  margin-top: 30px;
  display: flex;
  justify-content: end;
  gap: 20px;
}

.courseModalBody {
  display: flex;
  gap: 10px;
}

.analyticEdt,
.analyticDlt {
  font-size: 22px;
}

.analyticEdt {
  margin-right: 15px;
}

.MuiModal-root .MuiBox-root {
  border: none;
  border-radius: 10px;
}

.subjectGrid .MuiSkeleton-root {
  width: 20.5%;
  height: 118px;
  min-width: 190px;
  flex-grow: 1;
  max-width: 33%;
  min-height: 180px;
  border-radius: 14px;
}

.addCourseBody .ms-TextField,
.addCourseBody .ms-ComboBox-container {
  min-width: 90px !important;
}

.course_modal .ms-Dialog-inner {
  box-sizing: border-box;
}

.course_modal .ms-Modal-scrollableContent {
  width: fit-content;
}

.course_modal .ms-Modal-scrollableContent>div {
  max-width: 400px;
}

@media (min-width: 480px) {
  .course_modal .ms-Dialog-main {
    max-width: max-content;
  }
}

.error_text,
.error_text textarea {
  outline: none;
  color: red;

}

.error_text textarea {
  border: 2px solid red;
}

.ant-progress-inner {
  background-color: #fff !important;
}

.publishPop ul {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.publishPop ul li {
  font-weight: 500;
  cursor: pointer;
}

.modalHeading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.dateTimePicker {
  display: flex;
  gap: 10px;

}

.dateTimePicker div {
  flex-grow: 1;
}

.uptopModal {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.scheduledTime{
  color: blue;
  cursor: pointer;
}
.scheduledTime:hover{
color: purple;
}
.subjectHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.availableCourse{
  font-size: 12px;
  color: white;
  background-color: green;
  padding: 4px 6px;
  border-radius: 6px;
}
.ant-select-dropdown{
  z-index: 111111 !important;
}
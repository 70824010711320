.reguarPagebtn {
    background-color: #dddddd;
    transition: 0.5s !important;
    font-weight: 500;
    font-size: 15px;
    padding: 9px 10px;
}

.activePage {
    transition: 0.5s;
    background-color: gray;
    color: white;
}

.creatorStudioPageBody {
    display: flex;
    transition: 1s;
}

.creatorStudioPageSection {
    flex-grow: 1;
    /* max-height: calc(100vh - 212px); */
    overflow: auto;
    padding: 15px 0px;
}

.pageTabBar {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    margin-bottom: 20px;
    /* flex-wrap: wrap; */
    gap: 5px;
}

.regularbar {
    justify-content: end;
}

.openedBar {
    background-color: #ffffff;
    transition: 1s;
    border-radius: 5px;
}

.closedBar {
    background-color: transparent;
    transition: 0.5s;
}

.creater_studio_panel {
    width: 358px !important;
}

@media (min-width: 480px) {
    .creater_studio_panel .ms-Panel-main {
        width: 390px !important;
    }
}

.tree-node {
    margin: 6px 0px;
}

.savePageBtn {
    background-color: #80d100;
    border: none;
    border-radius: 5px;
    padding: 10px;
    min-width: 80px;
    color: white;
    margin-top: 12px;
    cursor: pointer;
}

.creatorStudioBody {
    background-color: #FFFFFF;
    width: 100%;
    padding: 0px 20px 20px 20px;
    box-sizing: border-box;
    /* border-radius: 15px; */
    display: flex;
    position: relative;
    /* max-height: calc(100vh - 135px); */
    overflow: auto;
}

.creatorStudioSearchBar {
    background-color: #EAF0FF;
    border: none;
    border-radius: 40px;
}

.chapterBody {
    display: flex;
    padding: 0 20px 0 0;
    /* min-height: calc(100vh - 158px); */
}

.chapter {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    /* border-bottom: 1px solid #ABB0BF; */
    padding: 8px 10px;
    border-radius: 5px;
    margin: 5px 0px;
    cursor: pointer;
}

.activeChapter {
    background-color: #e1e1e1;
}

.chapter:hover {
    /* background-color: #FFFFFF; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.module {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    /* border-bottom: 1px solid #ABB0BF; */
    padding: 5px 5px;
    padding-left: 20px;
}

.pagesHeadingLabel {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    /* border-bottom: 1px solid #ABB0BF; */
    padding: 5px 5px;
    padding-left: 40px;
}

.module:hover {
    background-color: #FFFFFF;
}

.activePageHeadingLabel {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    /* border-bottom: 1px solid #ABB0BF; */
    padding: 5px 5px;
    padding-left: 40px;
    cursor: pointer;
}

.pageHeadingText {
    padding: 2px 10px;
}

.pagesHeadingLabel:hover .pageHeadingText {
    background-color: #FFFFFF;
    cursor: pointer;
}

.chapterList {
    background-color: #EAF0FF;
    padding: 15px 15px 0 15px;
    position: relative;
    flex-grow: 4;
    grid-row: 1;
    border-radius: 20px;
    min-width: 300px;
    overflow-y: auto;
    max-height: calc(100vh - 278px);
    min-height: calc(100vh - 278px);
}

.chapterActions>i {
    padding: 0 5px;
    cursor: pointer;
}

.addModules {
    padding: 1px;
    background-color: #D9D9D9;
    border-radius: 50%;
}


.floatingAddButton {
    position: fixed;
    right: 30px;
    bottom: 10px;
    background-color: #EAF0FF;
    padding: 10px 2px 10px 2px;
    border-radius: 50%;
    cursor: pointer;
}

.floatingAddButton>i {
    font-weight: bold;
    margin: 0 10px;
}

.chapterHeading {
    width: 100%;
}

.chapterHeading>div>div>textarea {
    background-color: #EAF0FF !important;
}

.chapterCreator {
    padding-left: 20px;
    padding-top: 20px;
    min-height: calc(100vh - 280px);
    flex-grow: 6;
    grid-row: 2;
}

.mediaAndDetails {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 10 !important;
    flex-wrap: wrap;
}

.media {
    flex: 2;
    text-align: center;
    /* min-height: 150px; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 200px;
    padding-left: 20px;
}

.chapterDetails {
    flex-grow: 5;
    padding-left: 20px;
}

.cdetails>div>div {
    height: 350px !important;
}

.mediaLabel {
    color: #D9D9D9;
}

.addMedia {
    background-color: #D9D9D9;
    font-size: 30px;
    border-radius: 50%;
}

.badge {
    /* background-color: #f8beb9; */
    /* background-color: #e6e6e6; */
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0px 2px;
    border: 2px solid #e6e6e6;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}



.badgeContainer {
    float: right;
    margin-top: 20px;
}

.headerBadgeContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: 20px;
}

.headerBadge {
    background-color: #dddddd;
    padding: 5px;
    border-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
    white-space: nowrap;
}

.activeHeaderBadge {
    background-color: #787878;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
    white-space: nowrap;
}

.prevNextBody {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 30px 0;
    font-size: 15;
}

.prevNextIcon {
    margin-top: 15px;
}

.footer .big_screen_footer {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    /* margin: 20px 0px; */
    align-items: baseline;
}

.ms-List-cell {
    min-height: 15px !important;
}


.modules {
    font-size: 14px !important;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-top: 10px;
    padding-right: 31px;
}

.addChapter {
    text-align: center;
    font-size: 30px;
    margin-top: 50px;
    cursor: pointer;
}

.addFieldsIcon {
    text-align: center;
    font-size: 16px;
    margin-top: 36px;
    cursor: pointer;
}

.questionFieldBody {
    width: auto;
    padding-left: 20px;
    min-width: 270px;
    flex-grow: 1;
}

.mcqFields .questionFieldBody {
    padding-left: 0px;
}

.mcqFields {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    column-gap: 10px;
    align-items: center;
}

.questionFieldBody>div>div>div>textarea {
    background-color: #EAF0FF !important;
}

.questionFields {
    flex: 7;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 300px;
    padding-left: 10px;
}

.questionFields p {
    padding: 0px 10px;
    font-weight: 500;
}

.questionFields .jodit p {
    font-weight: 400;
}

.imageVerificationBody {
    padding-left: 20px;
    flex-grow: 5;
}

.ms-TextField {
    min-width: 10px !important;
    width: 100%;
}

.pager {
    padding-top: 10px;
}

.page {
    background-color: #dddddd;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
}

.pageActive {
    background-color: #787878;
    padding: 5px 10px;
    color: #FFFFFF;
    margin: 5px;
    border-radius: 5px;
}

.courseName {
    font-size: 18px;
    font-weight: 500;
    max-width: 330px;
    /* padding: 10px 0 0 0; */
}

.courseClassName {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 0 10px 0;
}

.courseDesc {
    padding: 5px 0 0 0;
}

.pageNotFound {
    font-size: 20px;
    text-align: center;
    padding-top: 200px;
}

.notFoundDesc {
    font-size: 14px;
    padding-top: 10px;
}

.jodit-status-bar {
    display: none !important;
}

.jodit-react-container {
    margin-top: 10px;
}

.jodit-container {
    max-height: 250px !important;
    min-height: 250px !important;
    overflow: scroll !important;
}

.jodit_fullsize {
    min-height: 99vh !important;
    overflow: hidden !important;
    padding: 0;
}

.delete {
    color: red;
    cursor: pointer;
}

/* .ms-Toggle {
    margin-top: 35px !important
} */

.mcqOptionsRenderedField {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}

.deleteOption {
    margin-top: 36px;
    color: red;
    cursor: pointer;
}

img {
    /* height: 100px; */
    max-width: 100%;
}

.analyticEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text_editor .jodit-react-container .jodit-container {
    min-height: 341px !important;
}

.text_editor .jodit-react-container .jodit-container .jodit-workplace {
    min-height: 240px !important;
}

.text_editor .jodit-react-container .jodit-container .jodit-workplace div:nth-child(1) {
    min-height: 240px !important;
}

.analyticEdit .enableEdit {
    position: absolute;
    right: 14px;
    top: 38px;
}

.studioTimer {
    font-weight: 600;
    font-size: 20px;
}

/* .test_heading {
    padding: 20px 0px;
} */

.small_screen_footer {
    display: none;
}

.footer {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    padding-bottom: 20px;
    justify-content: center;
}

.footer button {
    white-space: nowrap;
}

@media(max-width: 1100px) {
    .big_screen_footer {
        display: none;
    }

    .small_screen_footer .small_screen_pagination {
        display: block;
    }
}

.ms-Panel-content .chapterBody .chapterList {
    /* min-width: 285px !important; */
    border-radius: 20px;
}

.ms-Panel-content {
    padding: 10px !important;
}

.collapse_menu {

    border: 1px groove #aba9a9;
    display: inline-flex;
    padding: 5px;
    align-items: center;
    border-radius: 5px;
    display: none;
    position: absolute;
    top: 24px;
    background: white;
    right: 45px;
}

.collapse_menu i {
    font-size: 20px;
    padding: 2px;
}

.createrStudioSection {
    padding: 20px 0px;
}

@media(max-width: 479px) {
    .creater_studio_panel {
        inset: 0px 0px 0px 0px !important;
    }
}

.creatorStudioSidebars {
    background: #eaf0ff;
    max-height: calc(100vh - 180px);
    min-height: calc(100vh - 180px);
    overflow: auto;
}

.collapsible_sidebar {
    position: relative;
}

@media(max-width:800px) {
    .ms-Panel-content .chapterBody .chapterList {
        min-width: 10px !important;
        width: 100%;
        max-height: calc(100vh - 185px);
    }

    .creater_studio_panel .ms-Panel-main .ms-Panel-contentInner .ms-Panel-scrollableContent {
        overflow-y: hidden !important;
    }

    .chapterBody {
        padding: 0;
    }

    .createrStudioSection {
        display: none;
    }

    /* .creatorStudioSidebars {
        display: none;
    } */

    .creatorStudioSidebarsMobile {
        display: block;
    }

    .collapse_menu {
        display: inline-flex;
        left: 5px !important;
        right: inherit;
        border: 2px solid;
    }

    .admin_menu {
        top: -43px !important;

    }

    .student_menu {
        top: -43px !important;
    }

    .test_student_menu {
        top: 43px !important;
    }

    .pasteImageSection {
        display: none !important;
    }
}


semantics mrow {
    display: flex;
    flex-wrap: wrap;

}

.questionText p math,
.questionText p math semantics,
.questionText p math semantics mtable,
.questionText p math semantics mtable mtr,
.questionText p math semantics mtable mtr mtd {
    width: 100%;
}

.questionText p math semantics mtable mtr mtd {
    display: flex;
    flex-wrap: wrap;
    min-width: 33px;
    align-items: center;
}

.answer_heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
}

.big_screen_footer button {
    min-height: 32px !important;
    height: auto !important;
    white-space: nowrap;

}

.not_submitted {
    display: none;
}

.counterPara {
    margin-right: 0px;
    padding: 8px 20px;
    margin-top: 0px;
    text-align: center;
}

.creator_top {
    padding-right: 20px !important;
    align-items: baseline;
    flex-wrap: wrap-reverse;
    gap: 10px;
}

.footer_save {
    display: flex;
    margin: 20px;
}

@media(max-width:800px) {
    .createrStudioCont {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .page_header {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
}

.disableRegular,
.disableQuestion {
    background-color: #eeecec;
    cursor: no-drop;
    pointer-events: none;
    border-radius: 7px;
    padding: 5px;
    color: #bcb9b9;

}

.media .ant-upload-wrapper {
    display: block;
}

.regular_footer {
    justify-content: center !important;
}

.chapterWithPagination {
    width: 100%;
}

.headerBadgeRegular {
    justify-content: end !important;
}

.header_text_editor .jodit-workplace div p {
    font-weight: 500 !important;
}

.preview_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.preview_img i {
    font-size: 22px;
    color: red;
}

.openCameraBtn {
    border: none;
    background: none;
    border: 2px solid lightgray;
    border-radius: 4px;
    padding: 8px 0px;
    font-size: 18px;
    cursor: pointer;
    min-width: 120px;
}

.openCameraBtn i {
    margin-right: 10px;
}

.cameraDilouge .ms-Dialog-main .ms-Modal-scrollableContent .ms-Dialog-header {
    display: none;
}

.cameraDilouge .ms-Dialog-main .ms-Modal-scrollableContent .ms-Dialog-inner {
    padding: 0;
}

.camera_modal {
    width: fit-content !important;
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

@media(min-width : 480px) {
    .cameraDilouge .ms-Dialog-main {
        max-width: fit-content;
    }

    .react-html5-camera-photo>img,
    .react-html5-camera-photo>video {
        height: 100vh !important;
    }
}

@media(max-width :1329px) {

    .react-html5-camera-photo>img,
    .react-html5-camera-photo>video {
        height: 100vh !important;
    }
}

@media(max-width:768px) {
    .camera_parent {
        width: 100vw !important;
    }

    .camera_modal {
        width: 100vw !important;
    }
}

.react-html5-camera-photo img {
    height: 100%;
}

.react-html5-camera-photo video {
    border-radius: 8px;
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
    background: black;
}

.camera_parent {
    /* width: 100vw; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    box-sizing: border-box;
}

.react-html5-camera-photo {
    width: fit-content !important;
}

.camera_parent i {
    color: white;
    position: absolute;
    right: 3px;
    z-index: 10000000000000000;
    top: 3px;
    font-size: 20px;
}

.image_action {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.preview_img img {
    /* height: auto !important; */
    width: 100%;
}

.img_upload_btn {
    display: flex;
    gap: 5px;
    background: #eaf0ff;
    font-size: 15px;
    padding: 7px;
    border-radius: 5px;
    cursor: pointer;
}

.img_upload_btn i {
    font-size: 16px;
    font-weight: 600;

    color: blue;
}

.img_upload_btn p {
    font-size: 12px;
    font-weight: 600;
}

.ant-image-preview-img-wrapper img {
    height: auto !important;
}

.preview_body {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-top: 20px;
}

.mediaAndName .ant-upload-list-item-container {
    display: none;
}

.questionFieldBody .ant-image-img {
    max-width: 100% !important;
    /* height: 150px !important; */
}

.uploaded_img img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.uploaded_img {
    margin: 10px 0px;
}

.preview_camera_modal .App {
    min-height: 56vh !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 5px !important;
}

.preview_camera_modal {
    background-color: white !important;
    min-width: 500px;
    border-radius: 10px;
}

.preview_camera_modal i {
    color: black;
}

.ReactCrop img {
    max-height: 75vh !important;
    max-width: 90vw !important;
    margin: auto;
}

.camera_parent .App i {
    position: initial;
    z-index: 1000000000;
    font-size: 25px;
}

.modalFooterBtn {
    width: 100%;
    text-align: end;
    margin-top: 20px;
    display: flex;
    justify-content: end;
    gap: 10px;
}

.rotateAndCrop {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    /* margin: 10px 0px; */
}

.ReactCrop {
    min-height: 300px;
}

.rotateAndCrop span {
    font-size: 12px;
    white-space: nowrap;
}

@media(max-width:500px) {
    .preview_camera_modal {
        min-width: 300px !important;
    }
}

.mobile_camera_parent {
    padding: 0;
}

.saveBtnParent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.studentTestHeader {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
}

.studentTestHeader {
    width: 100%;
}

.studentTestSection {
    width: 100%;
    padding: 10px 20px;
    min-height: calc(100vh - 240px);
}

.fullScreen {
    min-height: calc(90vh - 62px);
}

.imageWithText {
    display: flex;
    margin-top: 40px;
}

.creatorStudioSidebar {
    /* background-color: #eaf0ff; */
    margin-right: 10px;
}

.creatorBreadcrumb {
    padding-right: 20px;
    align-items: center;
}

.outerActionbtn {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.outerActionbtn i {
    font-size: 30px;
}

.textEditorHeader {
    width: 100%;
}

@media(max-width:800px) {
    .imageWithText {
        flex-wrap: wrap;
        margin-top: 5px;
    }

}


.disabledbtn {
    background-color: #aba9a9;
    cursor: initial;
}

.creatorStudioQuestionSection {
    width: 100%;
    min-height: 200px;
    /* border: 1px solid; */
    max-height: calc(100vh - 180px);
    overflow: auto;
}

.studentTestSection ol,
.studentTestSection ul {
    margin-left: 1em;
}

.studentTestSection ul {
    list-style: initial;
}

.heading_text ol,
.heading_text ul {
    margin-left: 1em;
}

.heading_text ul {
    list-style: initial;
}

.imageAndCheckbox {
    display: flex;
    gap: 20px;
    margin-top: 25px;
}

.studentMcqOptions {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* min-width: 300px; */
}

.mcqButtons {
    display: flex;
    gap: 10px;
}

.mcqButtons button {
    min-width: fit-content;
}

.successAnimation {
    position: absolute;
    height: 200px;
    width: 200px;
    left: 10%;
    top: -50px;
}

.checkboxImages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}

.correctAnswer {
    background-color: green;
}

.wrongAnswer {
    background-color: #c7c7c7;
    padding: 4px;
    border-radius: 6px;
}

.wrongAnswer label span {
    color: white !important;
}

.correctAnswer label span {
    color: white !important;
}

.correctAnswer {
    background-color: #80d100;
    padding: 4px;
    border-radius: 6px;
}

.loadingSidebar {
    position: absolute;
    min-height: 100%;
    background: #0000003b;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ms-Spinner-circle {
    height: 50px !important;
    width: 50px !important;
}

.submitButtons {
    display: flex;
}

.ant-image-img {
    margin-top: 10px !important;
}

.ant-upload-hint-mobile {
    font-size: 18px;
    font-weight: 500;
    display: none;
}

.pageTabBar .common_button {
    margin: 0px 4px;
}

.timeAndSubmit {
    text-align: end;
}
.filter_edit{
    background-color: #f6f6f6 !important;
    color: black !important;
}

@media(max-width: 800px) {
    .imageAndCheckbox {
        flex-direction: column;
        margin-top: 15px;
    }

    .checkboxImages {
        width: 100%;
    }

    .creatorStudioSidebar {
        margin-right: 0px !important;
    }

    .studentTestSection {
        padding: 10px 0px;
    }

    .homeworkElementsContainer {
        margin-right: 0;
        padding: 10px 10px;
    }

    .ant-image-img {
        max-width: 100% !important;
        width: auto !important;

    }

    .creatorBreadcrumb {
        padding-right: 0;
    }

    /* .test_heading {
        padding: 0px 5px;
    } */

    .enableEdit,
    .activeEdit {
        position: absolute;
        top: -36px;
        right: 5px;
    }

    .downloadPdfIcon {
        position: absolute;
        top: -34px;
        right: 90px;
    }

    .ant-upload-drag-icon {
        margin-bottom: 0 !important;
    }

    .media {
        flex-direction: row;
        min-height: auto;
        flex-wrap: wrap;
    }

    .mediaAndName {
        width: 100%;
    }


    .ant-upload-hint {
        display: none;
    }



    .questionFieldBody,
    .media,
    .preview_body,
    .questionFields,
    .pageTabBar {
        padding: 5px;
    }

    .preview_body {
        margin-top: 10px;
    }

    .pageTabBar {
        gap: 0px;
        margin-bottom: 0;
    }



    .saveBtnParent {
        margin-bottom: 15px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .ant-upload-drag-container {
        display: flex !important;
        justify-content: center;
        gap: 10px;
        align-items: center;
    }

    .creatorStudioPageSection {
        padding-top: 0;
    }
}

@media(max-width:800px) {
    .openCameraBtn {
        display: none;
    }

    .ant-upload-hint-mobile {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .studentTestHeader {
        align-items: normal;
        padding-left: 40px;
    }

    .timeAndSubmit {
        display: flex;
        flex-direction: column;
        text-align: end;
    }
}

.swipeBody {
    background-color: #787878;
}

.noPagesFound {
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.noPagesFound p {
    font-size: 26px;
    color: darkgray;
}

.leftBtngr,
.rightBtnGr {
    display: flex;
    justify-content: space-between;
}



.creatorStudioSwipe .react-swipeable-view-container {
    padding-top: 0;
}

.time_text {
    font-size: 14px;
}

/* .heading_text{
    margin-top: 15px;
} */
.saveBtnParent button {
    padding: 8px 10px;
}

.submittedPage {
    background-color: rgb(219 255 160) !important;
}

.image_parent {
    display: flex;
    flex-direction: column;
    gap: 15px;

}

.image_feild_parent {
    display: flex;
    gap: 15px;
    padding: 10px 0px;
    flex-wrap: wrap;
}

.mediaWithImages {
    display: flex;
    justify-content: center;
    width: 100%;
}

.image_parent_section {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.image_section {
    width: 55%;
    min-width: 300px;
}



:where(.css-dev-only-do-not-override-1km3mtt).ant-image .ant-image-img {
    max-height: 200px;
    width: auto;
}


@media(max-width:1000px) {
    .imageWithText {
        flex-wrap: wrap;
    }

    .image_parent_section {
        flex-direction: column;
    }
}

.checkbox_toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

.checkbox_toggle label {
    cursor: pointer;
}

input[type=checkbox] {
    background-color: #002B4E !important;
    accent-color: rgb(0, 120, 212);
    color: white;
    height: 30px;
    min-width: 20px;
}

input[type=checkbox]:checked {
    accent-color: rgb(0, 120, 212);
}

.maiiiiii li {
    max-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px solid;
}

.chapterName {
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
}

.image_parent .chapterName {
    padding-left: 0;
}

.questionFieldBody label {
    color: gray;
    font-size: 12px;
}

.pageLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
}

.MuiAlert-standardError {
    margin-bottom: 5px;
}

.validationError {
    border: 1px solid red;
    box-sizing: border-box;
    background: #ffeded;
    color: red;
}

.swipeableCard {
    width: 95%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    /* max-height: calc(100vh - 300px); */
    overflow: auto;
}

.checkShadow {
    width: 100%;
    display: flex;
    justify-content: center;
}

.courseTypeName {
    font-size: 14px;
}

.creatorStudioParentBody {
    padding-top: 0px;
    padding-bottom: 0px;
}

.mcqCheckbox {
    display: flex;
    align-items: center;
    gap: 10px;
}


.hiddenDescription {
    display: none;
    transition: all 1s ease-in;

}

.showDescription {
    display: block
}

.moreBtn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: blue;
}

/* @media(max-width:800px) {
    .creater_studio_panel .ms-Panel-main {
        display: none !important;
    }
} */
.explanation_section {
    background-color: #eaf0ff;
    padding: 6px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.downloadBtn {
    background-color: #80d100;
    border: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: white;
    margin: 7px 0px;
}

.questionFields .jodit-workplace p {
    padding: 0 !important;
}

.csActionBtn {
    display: flex;
    gap: 10px;
    align-items: center;
}

.importList {

    background-color: #eaf0ff;
    padding: 4px 4px;
    border-radius: 8px;
}

.importList li {
    min-width: 180px;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    gap: 5px;

}

.importList li:hover {
    background-color: #fff;
}

.importList li span p {
    width: 150px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.pasteImageButton {
    min-width: 130px;
    min-height: 45px;
}

.pasteImageSection {
    display: flex;
    width: 100%;
    gap: 10px;
}

.pasteImageSection button {
    flex-grow: 1;
}

.fontBtn button {
    border: none;
    outline: none;
    background: transparent;
    /* background: #f5f5f5; */
    padding: 0px 0px;
    border-radius: 6px;
    cursor: pointer;
}

.fontBtn {
    display: flex;
    justify-content: end;
    gap: 15px;
    align-items: center;
    font-size: 14px;
}

.autofillInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.autofillInputContainer .ms-TextField {
    width: auto;
}

.resultQuestion {
    margin: 15px 0px;
}

.dq_heaing {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.answerPopover {
    min-height: 200px;
    min-width: 400px;
}

.autofillBtn {
    background-color: var(--color-primary);
    color: white;
    margin: auto;
}

.pop_save_btn {
    background-color: var(--color-primary);
    color: white;
}

.student_logout .mobileRightHeader {
    display: block !important;
}

.logout_head {
    display: flex;
    gap: 5px;
}

.creatorStudio {
    padding: 10px 0px;
}

.ps-sidebar-container {
    overflow: hidden !important;
}

.subject_filter {
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    flex-wrap: wrap;
}

.filter_btn {
    background: #000000;
    color: white;
    width: max-content;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 30px;
    white-space: nowrap;
}

.filter_submit {
    width: max-content;
    background-color: #08c;
    color: white;
}

.filter_section {
    padding: 10px 20px;
}

/* .difficulty_container {
    width: 150px
} */

/* .MuiPaper-elevation {
    width: 150px;
} */
.cs_filters {
    display: flex;
    gap: 10px;
    align-items: center;
}

.small_parent {
    overflow: hidden;
}

.cs_input_range {
    border: none;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    background-color: #f5f5f6;


}

.numerical_ans {
    display: flex;
    gap: 15px;
    align-items: center;
}

.disable_add {
    pointer-events: none;
}

.questions-outer-cnt {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
}

.pdfImagesCnt {
    max-width: 40%;
    max-height: 80vh;
    overflow-x: scroll;
}

.courseDiscription {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media(max-width:800px) {
    .courseDiscription {
        padding-left: 20px;
    }
}

@media(max-width:506px) {

    .courseDiscription>div,
    .questionbank_header {
        width: 100%;
    }
}

.questionHeading>span {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;

}

.ql-editor p,
.heading_text p {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
}

.questionbank_header {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media(max-width:568px) {
    /* .questionbank_header {
        flex-wrap: wrap;
    } */
}

.resizable-container {
    display: flex;
    height: 100vh;
    max-width: 100%;
    overflow: auto;
    user-select: none;
    /* Prevent text selection during dragging */
}

.box {
    height: 100%;
    overflow: auto;
}

.dltComponent {
    /* padding: 10px; */
    border-radius: 10px;
}

.dltComponent li {
    padding: 4px 10px;
    cursor: pointer;

}

.dltComponent li:hover {
    background-color: #f5f5f5;
}

.resizer {
    width: 5px;
    position: relative;
    cursor: ew-resize;
    background-color: #ccc;
    position: relative;
    z-index: 10;
}

.resizer::after {
    content: "";
    position: absolute;
    top: 10px;
    left: -8px;
    background-image: url("../assets/left-and-right.png");
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;

}

.questionbank_header .common_button {
    white-space: nowrap;
}

.cs_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.cs_action_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.questionbank_header .MuiFormControl-root .MuiAutocomplete-input {
    width: 100px !important;
}

.question_bank_header {
    justify-content: space-between;
}

.q_pagination {
    display: flex;
    justify-content: center;
}

.MuiAutocomplete-option {
    border-bottom: '1px solid #ddd'
}

.disabledQuestion {
    background-color: gray;
    cursor: no-drop;
}

.questionbank_header .MuiFormLabel-root {
    top: -5px;
}
.questionbank_header .Mui-focused .MuiFormLabel-root{
    top: 0px;
}
.questionbank_header .MuiInputBase-root{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.csActionBtn .buttonWithicon {
    height: 30px;
}
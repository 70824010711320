body {
  background-color: #ffffff !important;
}

.wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-signin {
  max-width: 320px;
  margin: 0 auto;
  background-color: #fff;
  /* padding: 15px 35px 45px; */
  text-align: left;
  /* padding-top: 100px; */
  width: 100%;
}

.form-signin-heading,
.checkbox {
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: normal;
  /* padding-left: 40px; */
  text-align: center;
}

.loginField>div>div {
  /* max-width: 320px; */
  max-height: 45px;
  /* width: 320px; */
  height: 45px;
  border-radius: 10px;
  border: none;
  color: #000000 !important;
}

.loginField {
  margin-top: 30px;
}

.loginField>div>div>input {
  color: #000000 !important;
  border-radius: 5px !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000 !important;
  opacity: 1;
  /* Firefox */
}

.form-control {
  position: relative;
  font-size: 16px;
  padding: 0 10px 0 10px;
}

.login_btn {
  background-color: #21ea85;
  /* width: 320px; */
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  margin-left: 0px;
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  padding: 23px 0px 45px;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 17px;
}

.ms-TextField-errorMessage {
  padding-left: 0px;
  font-weight: 500;
}

.resetPasswordButton {
  margin-top: 20px;
}

.setUpPasswordLabel {
  padding-left: 40px;
}

.error {
  color: #ffffff;
  background-color: red;
  border-radius: 3px;
  padding: 15px;
  font-size: 14px;
  width: 290px;
  margin-left: 40px;
}

.loginError {
  margin-left: 0;
}

.logoBody {
  display: flex;
  justify-content: center;
}

.logoBody>img {
  max-height: 100px;
  width: auto;
  height: auto;
}

.clientLogoBody {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;

}

.clientLogoBody>img {
  height: 40px;
}

.password_feild {
  position: relative;
}

.password_feild .password_eye {
  height: 20px;
  position: absolute;
  right: 11px;
  top: 14px;
  cursor: pointer;
}

.loginSubmitBtn {
  margin-top: 30px;
}

.ms-TextField-fieldGroup::before {
  display: none !important;
}

.loginSubmitBtn p {
  text-align: end;
  margin-top: 10px;
  cursor: pointer;
}

.loginSubmitBtn p:hover {
  color: blue;
}

.loginSubmitBtn button span {
  margin-right: 10px;
}
.successfull_body {
    display: flex;
    height: calc(100vh - 40px);

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.successfull_body img {
    height: 150px;
}

.success_text_section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
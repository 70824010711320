.ant-slider-track{
    background-color: #306eb3;
}
.ant-slider-horizontal{
    width: 100%;
}
.croppedimagePreview{
    text-align: center;
}
.ant-tooltip{
    z-index: 1111111111;
}
.Crop-Controls{
    display: flex;
    width: 100%;
    gap: 16px;
}
.croppedimagePreview{
    display: none;
}
.rotateAndCrop svg{
    cursor: pointer;
}
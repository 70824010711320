.unread{
    position: relative;
}
/* .unread::before{
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: red;
    z-index: 1;
    border-radius: 100%;
    top: 4px;
    right: 10px;

}
.activeUnread::before{
    top: 15px;
    right: 20px;
} */